<template>
  <v-container fluid>
    <common-error
      v-if="isError"
      @fetchData="fetchCalendar"
    />
    <skeleton-loader
      v-else-if="isLoading"
    />
    <!-- Main -->
    <v-container v-else fluid>
      <v-row>
        <!-- calender -->
        <v-col class="pa-0" cols="12" md="8">
          <v-card tile class="mt-2">
            <Calender
              :dataCalender="dataCalender"
              :filters="filters"
              @fetchCalendar="fetchCalendar"
            />
          </v-card>
        </v-col>
        <!-- sidebar -->
        <v-col
          cols="12"
          md="4"
          class="catskill_white"
        >
          <OrderList
            :dataCalender="dataCalender"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import OrderList from './OrderList.vue';
import Calender from './Calender.vue';
import SkeletonLoader from './SkeletonLoader.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'schedule',
  created() {
    source = CancelToken.source();
    this.fetchCalendar();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  components: {
    OrderList,
    Calender,
    SkeletonLoader,
  },
  data() {
    return {
      isCreated: false,
      isLoading: false,
      isError: false,
      dataCalender: [],
      itemsTransporter: [],
      itemsCustomers: [],
      filters: {
        date: this.$route.query.date || dayjs().format('YYYY-MM-DD'),
        transporterId: '',
        shipperId: '',
      },

      // Loader
      isLoadingListTransporter: false,
      isLoadingListCustomers: false,
    };
  },
  methods: {
    dayjs,
    toggleLoading(boolean) {
      if (this.isCreated) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.isLoading = boolean;
    },
    async fetchCalendar(event) {
      if (event === 'next') {
        this.filters.date = dayjs(this.filters.date).add(1, 'month').format('YYYY-MM-DD');
      }
      if (event === 'prev') {
        this.filters.date = dayjs(this.filters.date).subtract(1, 'month').format('YYYY-MM-DD');
      }
      if (this.$route.query.date !== this.filters.date) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            date: this.filters.date,
          },
        });
      }
      try {
        this.toggleLoading(true);
        const res = await this.$_services.schedule.getListCalender(this.filters, source);
        const filterStatus = ['PROCESS', 'SCHEDULED', 'FINISHED', 'CANCELLED'];
        this.dataCalender = res?.data?.filter((data) => filterStatus.includes(data.status));
        this.isCreated = true;
      } catch {
        this.dataCalender = [];
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>
<style scoped>
  .v-card {
    box-shadow: none !important;
  }
  .catskill_white {
    background-color: #F1F5F8;
  }
</style>
