<template>
  <v-container fluid>
    <FullCalendar
      :options="calendarOptions"
      ref="fullCalender"
    />
    <v-row justify="end">
      <v-col cols="12" class="d-flex justify-end">
        <v-badge
          class="mr-5"
          dot
          left
          color="orange"
          offset-y="15"
          offset-x="-1"
        >
          <span class="caption">
            {{$_strings.schedule.label.SCHEDULED}}
          </span>
        </v-badge>
        <v-badge
          class="mr-5"
          dot
          left
          color="blue"
          offset-y="15"
          offset-x="-1"
        >
          <span class="caption">
            {{$_strings.schedule.label.ON_PROCESS}}
          </span>
        </v-badge>
        <v-badge
          class="mr-5"
          dot
          left
          color="green"
          offset-y="15"
          offset-x="-1"
        >
          <span class="caption">
            {{$_strings.schedule.label.ORDER_COMPLETED}}
          </span>
        </v-badge>
        <v-badge
          dot
          left
          color="red"
          offset-y="15"
          offset-x="-1"
        >
          <span class="caption">
            {{$_strings.schedule.label.ORDER_CANCEL}}
          </span>
        </v-badge>
      </v-col>
    </v-row>

    <!-- dialog date event -->
    <v-dialog
      v-model="dialog"
      width="400"
      scrollable
    >
      <v-card :loading="isLoadingDetailOrder" v-if="dataScheduleSelected.length">
        <v-card-title class="text-h6">
          <h3>{{convertStatusSchedule(dataScheduleSelected[0].status)}}</h3>
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 mb-2">
            <v-col class="pb-0" cols="12" v-for="(data, index) in dataScheduleSelected" :key="index">
              <v-menu
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    :color="colourOrder(data.status)"
                    class="white--text"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchCalendarExpand(data)"
                  >
                    {{data.cargoTitle}}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-content v-if="data.isLoading">
                    <div class="text-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>
                  </v-list-item-content>
                  <section v-else>
                    <v-list-item-title v-if="data.customer" class="pl-2 grey--text">
                      {{ $_strings.schedule.label.CUSTOMER_NAME }}
                    </v-list-item-title>
                    <v-list-item-content v-if="data.customer && data.customer.length === 0" class="pl-2">
                      -
                    </v-list-item-content>
                    <v-list-item-content v-else class="pl-2">
                      <section v-for="(customer, index) in data.customer" :key="index">
                        <v-row>
                          <v-col cols="auto" :class="index === 0 ? 'pt-0' : ''">{{index + 1}}.</v-col>
                          <v-col cols="auto" :class="index === 0 ? 'pt-0' : ''">
                            <h4>{{customer.shipperName}}</h4>
                            <span>{{customer.title}}</span>
                          </v-col>
                        </v-row>
                      </section>
                    </v-list-item-content>
                    <v-list-item-action class="d-flex pa-2 ma-0">
                      <v-btn
                        block
                        @click="viewOrderDetail(data)"
                        outlined
                      >
                        {{ $_strings.common.DETAIL }}
                      </v-btn>
                    </v-list-item-action>
                  </section>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import axios from 'axios';
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayjs from 'dayjs';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  props: {
    dataCalender: {
      type: Array,
      default: () => [],
    },
    filters: Object,
  },
  mounted() {
    document.querySelector('.fc-next-button.fc-button').addEventListener('click', () => {
      this.$emit('fetchCalendar', 'next');
    });
    document.querySelector('.fc-prev-button.fc-button').addEventListener('click', () => {
      this.$emit('fetchCalendar', 'prev');
    });
    if (this.dataCalender.length) this.setData();
  },
  watch: {
    dataCalender() {
      this.setData();
    },
    dialog(newVal) {
      if (!newVal) {
        source.cancel('CANCELED_BY_THE_USER');
      }
      source = CancelToken.source();
    },
  },
  data() {
    return {
      dialog: false,
      isLoadingDetailOrder: false,
      calendarOptions: {
        headerToolbar: {
          left: 'prev',
          center: 'title',
          right: 'next',
        },
        dayHeaderFormat: {
          weekday: 'long',
        },
        dayMaxEventRows: true,
        navLinks: false, // can click day/week names to navigate views
        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        locale: 'id',
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        firstDay: 1,
        dateClick: false,
        events: [
          {
            title: '', date: '', backgroundColor: '', id: '',
          },
        ],
        eventClick: this.handleDateEventClick,
        initialDate: this.$route.query.date || dayjs().format('YYYY-MM-DD'),
      },
      // PUSH AFTER CLICK DATE THAT HAS STATUS
      dataScheduleSelected: [],
    };
  },
  methods: {
    setData() {
      const dataCalender = [];
      this.dataCalender.forEach((dc) => {
        if (!dataCalender.find((d) => (d.date === dayjs(dc.pickupDate).format('YYYY-MM-DD')) && d.status === dc.status)) {
          dataCalender.push({
            date: dayjs(dc.pickupDate).format('YYYY-MM-DD'),
            backgroundColor: this.colourOrder(dc.status),
            status: dc.status,
          });
        }
      });
      this.calendarOptions.events = dataCalender;
    },
    colourOrder(type) {
      switch (type) {
        case 'SCHEDULED': return 'orange';
        case 'PROCESS': return 'blue lighten-1';
        case 'FINISHED': return '#66BB6A';
        case 'CANCELLED': return 'red';
        default: return 'grey';
      }
    },
    async fetchCalendarExpand(data) {
      const { id } = data;
      const indexTarget = this.dataScheduleSelected.indexOf(data);
      try {
        const targetData = { ...this.dataScheduleSelected[indexTarget] };
        this.$set(this.dataScheduleSelected[indexTarget], 'isLoading', true);
        const result = await this.$_services.schedule.getListExpandCalender(id, source);
        this.dataScheduleSelected.splice(indexTarget, 1, {
          ...targetData,
          customer: result?.data,
        });
      } finally {
        this.$set(this.dataScheduleSelected[indexTarget], 'isLoading', false);
      }
    },
    convertStatusSchedule(status) {
      switch (status) {
        case 'PROCESS':
          return 'Dalam Proses';
        case 'FINISHED':
          return 'Selesai';
        case 'SCHEDULED':
          return 'Terjadwal';
        case 'CANCELLED':
          return 'Dibatalkan';
        default:
          return status;
      }
    },
    handleDateEventClick(event) {
      const date = event.event.startStr;
      const { status } = event.event._def.extendedProps;
      const dataSchedule = [];
      const findSchedule = this.dataCalender.filter((dc) => (date === dayjs(dc.pickupDate).format('YYYY-MM-DD')) && status === dc.status);
      // REMOVE DUPLICATE shipmentGroupTitle
      findSchedule.forEach((fs) => {
        if (!dataSchedule.find((ds) => ds.cargoTitle === fs.cargoTitle)) {
          dataSchedule.push({
            ...fs,
            isLoading: false,
          });
        }
      });
      this.dataScheduleSelected = dataSchedule;
      this.dialog = true;
    },
    getStatus(status) {
      switch (status) {
        case 'Pesanan Dibuat':
        case 'Menunggu Konfirmasi':
        case 'Menunggu Konfirmasi Transporter':
        case 'Menunggu Konfirmasi Driver':
          return 'WAITING';
        case 'Terkonfirmasi':
        case 'Di Lokasi Penjemputan':
        case 'Di Lokasi Tujuan':
        case 'Pengiriman':
        case 'Penjemputan':
        case 'BAP - Konfirmasi Shipper':
        case 'BA Penerimaan - Ganti Lokasi Tujuan':
        case 'BAP - Konfirmasi':
        case 'Sampai Pelabuhan':
        case 'Muat Barang':
        case 'Menunggu Muat Barang':
        case 'Dalam Penyebrangan':
        case 'Bongkar Muatan':
        case 'Dalam Penerbangan':
        case 'BASP - Konfirmasi Shipper':
        case 'Dalam Proses':
          return 'ON_PROCESS';
        default:
          return 'FINISH';
      }
    },
    async fetchOrders(status, search, pickupDate) {
      try {
        this.isLoadingDetailOrder = true;
        const res = await this.$_services.order.orderList({
          page: 0,
          size: 1,
          pickupDateFrom: dayjs(pickupDate).subtract(1, 'month').format('YYYY-MM-DD'),
          pickupDateTo: dayjs(pickupDate).add(1, 'month').format('YYYY-MM-DD'),
          sort: 'latestStatus,desc',
        }, status, search, source);
        return res;
      } finally {
        this.isLoadingDetailOrder = false;
      }
    },
    async viewOrderDetail(data) {
      const {
        cargoTitle,
        pickupDate,
        id,
        latestStatus,
      } = data;
      const pickupDateFrom = dayjs(pickupDate).subtract('30', 'days').format('YYYY-MM-DD');
      const pickupDateTo = dayjs(pickupDate).add('30', 'days').format('YYYY-MM-DD');
      try {
        this.$router.push({
          name: 'order-detail',
          params: {
            id,
          },
          query: {
            status: this.getStatus(latestStatus),
            cargoTitle,
            pickupDateFrom,
            pickupDateTo,
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
<style lang="scss">
.fc .fc-toolbar {
  justify-content: center;
  .fc-toolbar-title {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    font-size: 1rem;
  }
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border-color: white;
}
.fc-daygrid-event-harness {
  width:15px;
  margin-top: 0px;
  display: inline-block;
}
.fc-col-header-cell.fc-day:nth-child(1) {
  border-top-left-radius: 10px;
}
.fc-col-header-cell.fc-day:nth-child(7) {
  border-top-right-radius: 10px;
}
.fc-col-header-cell.fc-day {
  padding: 0.3rem !important;
  border: 1px solid #1966be;
  background-color: #1966be;
  .fc-col-header-cell-cushion {
    color: #ffff;
  }
}
.fc-next-button.fc-button.fc-button-primary:active, .fc-prev-button.fc-button.fc-button-primary:active {
  background: #ffff;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.fc-next-button.fc-button.fc-button-primary, .fc-prev-button.fc-button.fc-button-primary {
  background: #ffff;
  border: 0;
}
.fc-icon.fc-icon-chevron-right, .fc-icon.fc-icon-chevron-left {
  color: #909090;
}
.fc-daygrid.fc-dayGridMonth-view.fc-view {
  border: 1px solid rgb(248, 240, 240);
  border-radius: 10px;
}
.fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0;
}
.fc-scrollgrid.fc-scrollgrid-liquid  tr td {
  border: 0;
}
.fc .fc-daygrid-day-top {
  justify-content: center;
}
.fc-day.fc-day-sun {
  a {
    color: red
  }
}
.fc-scrollgrid-section.fc-scrollgrid-section-header {
  th {
    border: none;
  }
}
.fc-event-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .fc-event-title.fc-sticky {
    font-size: 8px;
  }
}
</style>
